.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
}

.search-grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-product-card {
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 1;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    min-height: 500px;
    width: 100%;
}

.search-product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.search-labels-box {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.search-label {
    background-color: #0056b3;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.search-heart-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px !important;
    height: 24px;
}

.search-product-info {
    padding: 15px;
}

.search-product-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.search-product-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
}

.search-product-price {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.search-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.search-pagination-button {
    background: #0056b3;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.search-pagination-button:hover {
    background: #1f436c;
}
