/* Obecné styly */
body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: #2E8B57;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
}

h2 {
    color: #2E8B57;
    font-weight: bold;
    margin: 3px 0 !important;
    padding: 0 55px;
}

h3 {
    margin: 3px 0 !important;
    font-size: 1.5rem;
}

.container {
    position: relative;
    display: flex;
    /*gap: 20px;*/
    min-height: 80vh;
    text-align: center;
    flex-direction: column;
}

.col-md-12 {
    margin: 0 100px;
}

@media (max-width: 768px) {
    .col-md-12 {
        margin: 0 20px;
    }

    .navbar-items {
        margin: 0 20px;
    }
}

.zoom-img {
    cursor: pointer;
    transition: transform 0.5s ease;
}

.grid-item:hover .zoom-img {
    transform: scale(0.95);
}

.header-section {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%),
    url("../images/background2.jpg");
    margin: 0 !important;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.header-section > h1 {
    color: #FFFFFF;
    margin-top: 150px;
}

.header-section > a {
    font-size: 26px;
    font-weight: bold;
    margin-top: 40px;
}

.bounce-button {
    padding: 15px 30px;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 1;
    animation: bounce 2s infinite cubic-bezier(.68, -0.55, .27, 1.55);
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-4px);
    }
    60% {
        transform: translateY(-2px);
    }
}

.header-section > span {
    color: #FFFFFF;
    font-size: 26px;
}

.start {
    align-items: flex-start;
}

.w-100 {
    width: 100%;
}

.max-w-500 {
    max-width: 500px;
    width: 100%;
}

.home {
    display: flex;
    flex-direction: column;
    margin: 110px 0 150px 0;
    justify-content: center;
    z-index: 10;
    width: 100%;
    align-content: center;
}

.mar-t-20 {
    margin: 20px 0;
}

.green {
    color: #2E8B57 !important;
    margin-right: 5px;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.box-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 20px;
}

.wrap-box {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
}

.home-left {
    flex: 1;
}

.home-right {
    flex: 1;
}

.home-h1 {
    font-size: 4.5rem;
    color: #2E8B57;
    font-weight: bold;
    line-height: 114% !important;
    max-width: 50%;
    z-index: 10;
}

.home-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.add-box {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    background-color: #2E8B57;
    height: 290px;
    margin: 40px 0 100px 0;
    border-radius: 1rem;
    justify-content: center;
}

.home-box-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.white {
    color: white;
}

.search-bar {
    z-index: 10;
    gap: 15px;
    height: 75px;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding: 0 20px;
    justify-content: space-between;
}

.carousel-root {
    width: 100% !important;
}

.favourite-place {
    width: 100%;
    z-index: 10;
    height: 75px;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
}

.favourite-box {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.favourite-two {
    display: flex;
    flex: 2;
}

.favourite-one {
    display: flex;
    flex: 1;
}

.housemain {
    position: absolute;
    top: 80px;
    left: 80px;
    max-width: 450px;
    width: 100%;
}

.hut {
    position: absolute;
    top: 120px;
    max-width: 455px;
    left: 0;
}

.login_background {
    position: absolute;
    top: 100px;
    left: 0;
    width: 650px;
}

.house {
    position: absolute;
    top: -50px;
    right: 67px;
    width: 450px;
}

.graph {
    position: absolute;
    top: -100px;
    left: 30px;
    width: 300px;
}

.width-100 {
    width: 100%;
}

.button-60 {
    align-items: center;
    appearance: none;
    min-width: 170px;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-radius: .375em;
    box-shadow: none;
    box-sizing: border-box;
    color: #2E8B57;
    cursor: pointer;
    font-size: 1rem;
    height: 2.5em;
    justify-content: center;
    line-height: 1.5;
    padding: calc(.5em - 1px) 1em;
    position: relative;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
}

.button-60:active {
    border-color: #4a4a4a;
    outline: 0;
}

.button-60:focus {
    border-color: #485fc7;
    outline: 0;
}

.button-60:hover {
    border-color: #b5b5b5;
    color: white;
}

.button-60:focus:not(:active) {
    box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}

.search-btn {
    background-color: #2E8B57;
    color: white;
}

.home-box-left {
    padding: 20px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    height: 530px;
}

.box-padding {
    padding: 0 55px;
}

.box-padding-add {
    padding: 0 75px;
}

.types-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
}

.grid-item img {
    width: 100%;
    height: auto;
}

.carousel-img {
    height: 300px !important;
    border-radius: 1rem;
}

.types-img {
    width: 100% !important;
    height: 200px !important;
    border-radius: 1rem;
}

.carousel-text {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: white;
    text-align: left;
}

.product-header {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: white;
    text-align: left;
}

.favourite-carousel-box {
    position: relative;
}

.properties-text {
    bottom: 15px !important;
    left: 30px !important;
}

.relative {
    position: relative;
}

.product-box {
    border: solid 1px #111827;
    margin: -15px 0 15px 0;
    border-radius: 0 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.product-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    max-height: 300px;
    display: flex;
    min-height: 500px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
    transition: box-shadow 0.3s ease;
    height: 100%;
}

.product-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.product-image {
    width: auto;
    height: 200px !important;
    object-fit: cover;
}

.product-info {
    min-height: 250px;
    padding: 15px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.product-title {
    font-size: 1.5em;
    margin: 0 0 10px 0;
    color: #111827;
}

.product-description {
    color: #666;
    font-size: 0.9em;
    margin: 0 0 20px 0;
    flex-grow: 1;
}

.product-price {
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
    margin: 0;
}

.grid-item-product {
    width: 100%;
    max-width: 300px !important;
}

.item {
    cursor: pointer;
}

.properties-carousel .carousel {
    height: 238px;
}

.favourite-carousel .carousel {
    height: 649px;
}

.products-carousel .carousel {
    height: 565px;
}

.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #111827 !important;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #2E8B57 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #2E8B57 !important;
}

.labels-box {
    position: absolute;
    top: 10px;
    left: 10px;
    gap: 7px;
    display: flex;
    flex-direction: row;
}

.heart-icon {
    position: absolute;
    top: 10px;
    width: 30px !important;
    height: 30px !important;
    right: 10px;
    z-index: 15;
}

.heart-icon, .heart-icon-nav {
    width: 20px;
    height: 20px;
}


.heart-icon:hover {
    width: 50px !important;
}

.max-w-80 {
    max-width: 80% !important;
}

.label {
    width: auto;
    padding: 6px 8px;
    border-radius: 1rem;
    background-color: #2E8B57;
    color: white;
}

.carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
}

button:hover {
    background-color: #2E8B57;
}

/* Responzivní styly */
@media (max-width: 768px) {
    .housemain {
        display: none;
    }

    .properties-carousel .carousel {
        height: 100%;
    }

    .products-carousel .carousel {
        height: 100%;
    }

    .container {
        margin: 0 20px;
    }

    .favourite-carousel .carousel {
        height: 100%;
    }

    h2 {
        padding: 0 20px;
    }

    .box-padding, .box-padding-add {
        padding: 0 20px;
    }

    .home-h1 {
        font-size: 2.5rem;
        max-width: 100%;
    }

    .product-grid, .image-grid {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }

    .types-grid {
        grid-template-columns: 1fr;
    }

    .product-card {
        min-height: 400px;
    }


    .carousel-img, .types-img {
        height: auto;
    }

    .search-bar, .favourite-place, .favourite-box {
        flex-direction: column;
        width: 80%;
        align-items: center;

        padding: 20px;
    }

    .grid-item {
        grid-column: span 1 !important;
    }

    .home {
        margin: 80px 0 30px 0;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 0 10px;
    }

    h2 {
        padding: 0;
    }

    .home-h1 {
        font-size: 2rem;
    }

    .product-grid, .image-grid {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }

    .types-grid {
        grid-template-columns: 1fr;
    }

    .search-bar {
        height: auto;
    }

    .product-card {
        min-height: 300px;
    }

    .button-60 {
        min-width: 150px;
        font-size: 0.9rem;
    }

    .product-info {
        min-height: 150px;
    }
}

.text-block {
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 20px;
    align-items: flex-start;
    width: 100%;
    padding: 30px;
    margin-top: 20px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
}


.grid-item img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Zajistí, že obrázek pokryje celý box bez deformace */
    display: block;
}

.css-hlgwow {
    display: flex !important;
}

.mar-0 {
    margin: 0 !important;
}

.mar-10 {
    margin: 10px 0 !important;
}

.css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border: none !important;
    border-radius: 4px;
    border-style: none !important;
    border-width: 1px;
    box-sizing: border-box;
}

.css-13cymwt-control:hover {
    border: none !important;
    border-color: transparent !important;
}

footer {
    background-color: #2E8B57;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    margin-top: 50px;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px;
    margin: 0 120px;
}

.footer-section {
    flex: 1;
    padding: 20px;
    min-width: 250px;
}

.footer-section h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
}

.footer-section p, .footer-section ul, .footer-section li {
    margin: 5px 0;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section a {
    color: #fff;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}

.footer-bottom {
    padding: 10px 0;
    border-top: 1px solid #555;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.login-form {
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    margin: 150px auto 0 auto;
}

.login-form h1 {
    color: #FFFFFF;
    margin: 20px !important;
}

.white {
    color: #FFFFFF;;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.form-group input {
    width: -webkit-fill-available;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
}

.width-auto {
    width: auto !important;
}

.mar-20 {
    margin: 0 0 15px 0;
}

.mypage-container {
    padding: 20px;
    min-height: 80vh;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 100px 0 60px 0;
}

.add-chat-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.add-chat-button:hover {
    background-color: #2E8B57;
}

.product-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
}

.product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    max-width: 505px;
    width: 100%;
}

/* Media query pro max-width 1200px */
@media (max-width: 1200px) {
    .product-item {
        max-width: 100%;
    }
}


.actions button:hover {
    color: #2E8B57;
}

.error-message {
    color: red;
}

/* mypage.css */
.mypage-container {
    padding: 20px;
}

.mypage-header {
    margin-bottom: 20px;
}

.add-chat-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-image {
    width: 100px;
    height: 75px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

.actions button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.actions {
    display: flex;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    position: absolute;
    gap: 10px;
    right: 10px;
    text-align: left;
    top: 10px;
    flex-direction: column;
}

.error-message {
    color: red;
}

.product-image-container {
    background-size: cover;
    background-position: center;
    border-radius: 1rem;
    width: 100%;
    max-width: 650px;
    height: 300px;
    position: relative;
}

.product-name {
    color: #000000;
    max-width: 70%;
    border-radius: 0.5rem;
    background-color: #ffffff85;
    backdrop-filter: blur(10px);
    left: 10px;
    top: 20px;
    padding: 10px;
    position: absolute;
    text-align: left;
    width: 100%;
}

.action-button {
    margin-left: auto;
    height: 44px;
    width: 44px;
}

.show-info {
    bottom: 10px;
    font-size: 15px;
    color: #000000;
    max-width: 70%;
    border-radius: 0.5rem;
    background-color: #ffffff85;
    backdrop-filter: blur(10px);
    padding: 10px;
    left: 10px;
    position: absolute;
    text-align: left;
    width: 100%;
}

.navbar-left {
    align-items: center;
    display: flex;
    justify-content: start;
}

.logo {
    height: 60px;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.navbar-right i {
    margin-left: 15px;
    cursor: pointer;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.navbar-right i {
    margin-left: 15px;
    cursor: pointer;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #0e0e0e;
    margin: 3px 0 3px 5px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .menu {
        flex-direction: column;
        position: absolute;
        top: 60px; /* Adjust based on your navbar height */
        left: 0;
        right: 0;
        background-color: transparent;
        color: #4f4f4f;
        align-items: center;
        padding: 0;
        margin: 0;
        display: none; /* Hidden by default */
    }

    .menu.open {
        display: flex; /* Display menu when open */
    }

    .menu li {
        margin: 15px 0; /* Spacing between items in the mobile menu */
    }
}

.icon {
    width: 20px;
    height: 20px;
    color: #2E8B57;
    cursor: pointer;
}

.arrow-icon {
    width: 14px;
    height: 14px;
    color: #2E8B57;
    cursor: pointer;
    transition: transform 0.5s ease;
}

.menu li:hover > .arrow-icon {
    transform: rotate(180deg);
}

.arrow-icon:hover {
    transform: rotate(180deg);
}

.icon-box {
    width: 30px;
}

.icon:hover {
    width: 25px;
    height: 25px;
}

.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    flex-direction: column;
}

.max-w-100 {
    max-width: 100% !important;
}

.aling-start {
    align-items: flex-start;
}

.register-box {
    border-radius: 8px;
    margin: 20px 0;
    width: 100%;
    padding: 30px;
    background-color: #ffffff8a;
}

.centered {
    text-align: center !important;
}

.p-0 {
    padding: 0;
}

.register-h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #333;
}

.package-selection {
    display: flex;
    justify-content: space-between;
    margin: 4.5rem 0;
}

.package-column {
    flex: 1;
    padding: 0 1rem;
    text-align: left;

}

#payment-period {
    max-width: 70%;
    margin-left: 10px;
}

.form-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.display-no {
    display: none;
}

.m-auto {
    margin: auto;
}

.mar-1 {
    margin: 1rem 0 !important;
}

.package-first {
    min-width: 397px;
}

.package-column h3 {
    margin-top: 0;
}

.h-19 {
    height: 19px;
}

.icon-2 {
    width: 20px;
    height: 20px;
}

.package-column button:hover {
    background-color: #2E8B57;
}

.simple-container {
    max-width: 1200px;
}

.payment-summary {
    margin-top: 1rem;
}

.payment-summary h4 {
    margin: 0;
    font-size: 1.2rem;
}

.form-group:nth-child(2n) {
    margin-right: 0;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.flex-column {
    flex-direction: column;
}

.flex {
    display: flex;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}


.error-message {
    color: red;
    margin-top: 10px;
}

.registration-form {
    margin-top: 2rem;
}

.registration-form fieldset {
    border: none;
    margin-bottom: 1.5rem;
}

.registration-form legend {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.registration-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.registration-form input,
.registration-form select {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.registration-form button {
    margin-top: 1rem;
}

.reg-form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.form {
    width: 68%;
    margin: 63px auto;
}

.box-reg {
    width: 100%;
    text-align: start;
}

.h-211 {
    height: 132px;
    max-width: 482px !important;
}

.text-start {
    text-align: start;
}

.w-available {
    width: -webkit-fill-available;
}

.form-group-grouped {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-group-grouped .form-group {
    width: 31%;
    margin-right: 0;
}

.form-group-grouped .form-group.small {
    width: 20%;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.back-button {
    background-color: #6c757d;
}

.back-button:hover {
    background-color: #5a6268;
}

.add-product-form {
    display: flex;
    margin-top: 100px;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center
}

.product-detail {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 80px;
}


.product-body {
    display: flex;
    justify-content: space-between;
}

.detail-img {
    border-radius: 1rem;
    max-height: 700px;
    height: auto;
}

.product-left {
    flex: 3;
}

.product-description,
.product-properties {
    margin-top: 20px;
}

.product-right {
    flex: 1;
    padding: 20px;
    border-left: 1px solid #ddd;
}

.reservation-box {
    display: flex;
    flex-direction: column;
}

.reservation-box label {
    margin-bottom: 10px;
}

.reservation-box button {
    margin-top: 20px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.reservation-box button:hover {
    background-color: #2E8B57;
}

.slick-slide img {
    width: 100%;
    height: auto;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 60px 0 0 0;
    padding: 0 55px;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-button.confirm {
    background-color: red;
    color: white;
}

/* Obecné styly pro dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown .button-60 {
    position: relative;
    z-index: 1;
}

/* Styly pro submenu u lokalit */
.location-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 1000;
    width: 100%; /* Nastavte šířku podle potřeby */
}

.search-input {
    max-width: 100%;
    width: -webkit-fill-available !important;
    height: 34px;
    font-size: 16px;
    border: none !important;
    border-bottom: solid 1px #f9f9f9 !important;
    border-radius: 0 !important;
}

.location-submenu.active {
    display: block;
}

.location-submenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.location-submenu li {
    padding: 8px 12px;
    cursor: pointer;
}

.location-submenu li:hover {
    background-color: #f0f0f0;
}

.location-submenu .show-all {
    border-top: 1px solid #ddd;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    background-color: #f9f9f9;
}

.location-submenu .show-all:hover {
    background-color: #e0e0e0;
}

/* Styly pro submenu u data */
.date-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 1000;
    width: 200px; /* Nastavte šířku podle potřeby */
}

.date-submenu.active {
    display: block;
}

.date-submenu input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Styly pro submenu u hostů */
.guests-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 1000;
    width: 99%;
}

.guests-submenu.active {
    display: block;
}

.guests-submenu .group {
    padding: 8px 12px;
    display: flex;
    border-bottom: 1px solid #ddd;
    flex-direction: column;
    align-items: center;
}

.guests-submenu .group:last-child {
    border-bottom: none;
}

.guests-submenu .group label {
    display: flex;
    margin-bottom: 4px;
    justify-content: center;
    align-items: center;
}

.label-group {
    width: auto !important;
    display: flex !important;
    justify-content: space-between !important;
}

.guests-submenu .group .count {
    display: flex;
    align-items: center;
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    height: 100%;
    transition: height .15s ease-in;
}

.guests-submenu .group .count button {
    border: none;
    color: #2e8b57;
    font-size: 20px;
    background-color: transparent;
    padding: 4px 8px;
    cursor: pointer;
}

.guests-submenu .group .count span {
    margin: 0 8px;
}

/* Styly pro submenu u typu ubytování */
.accommodation-type-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 1000;
    width: 99%;
}

.accommodation-type-submenu.active {
    display: block;
}

.accommodation-type-submenu label {
    display: block;
    padding: 8px 12px;
    cursor: pointer;
}

.accommodation-type-submenu label:hover {
    background-color: #f0f0f0;
}

.footer-container {
    display: flex;
    justify-content: space-between;
}

.footer-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
    padding: 0 20px;
}

.footer-column p {
    margin: 5px 0;
    font-size: 15px;
}

.footer-column a {
    color: #ffffff;
    font-size: 15px;
    text-decoration: underline;
}

.footer-column.border-left {
    border-left: 1px solid #ccc;
    padding-left: 20px;
}

.social-icon {
    margin: 0 10px;
    color: white;
}

.add-accommodation-page {
    padding: 20px;
}

.add-accommodation-form {
    max-width: 800px;
    margin: 0 auto;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.my-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.my-header h2 {
    margin: 0;
}

.add-chat-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.add-product-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

input[type="text"],
input[type="number"],
textarea,
select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    height: 100px;
    resize: vertical;
}

input[type="file"] {
    margin: 5px 0;
}

.main-image-preview {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

button {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:disabled {
    background: #6c757d;
    cursor: not-allowed;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.success-message {
    color: green;
    margin-top: 10px;
}

.article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.article-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.article-card h2 {
    margin-top: 0;
}

.article-card p {
    margin: 0;
}

.main-form {
    display: flex;
    flex-direction: row;
    gap: 30px
}

.auto {
    width: auto !important;
}

.mar-30-0 {
    margin: 50px 0;
}

.dropzone {
    width: 300px;
    height: 300px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    background-repeat: no-repeat;
    margin-top: 24px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-row {
    flex-direction: row;
}

.gap-20 {
    gap: 20px
}

.dropzone p {
    font-size: 16px;
    color: #888;
}

.dropzone.active {
    background-color: #e0e0e0;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.suggestions-list {
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.suggestions-list li {
    padding: 8px;
    cursor: pointer;
}

.w-300 {
    width: 300px;
}

.suggestions-list li:hover {
    background: #eee;
}

.beetween {
    justify-content: space-between;
}

.justify-start {
    justify-content: flex-start;
}

.switch {
    background-color: #2e8b573b;
    display: flex;
    position: relative;
    width: 265px;
    height: 34px;
    border-radius: 1rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.ck.ck-editor__editable_inline > :last-child {
    min-height: 300px;
    margin-bottom: var(--ck-spacing-large);
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    touch-action: none;
    border-radius: 0.5rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    width: 61px;
    height: 33px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switchLabel {
    height: 34px;
    margin-top: 8px;
    text-align: end;
}

.switch-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 !important;
}

.mar-t-20 {
    margin-top: 20px;
}

.number-input {
    display: flex;
    align-items: center;
}

.number-input input {
    display: flex;
    text-align: center;
    width: 37px;
    padding: 0;
    border: none;
    background: transparent;
    align-items: center;
}

.number-input button {
    background-color: transparent;
    border: none;
    color: #2e8b57;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 27px;
    font-weight: bold;
}

.gap-40 {
    gap: 40px
}

.save-button {
    margin: auto 0 auto auto;
    width: 200px;
    height: 38px;
}

.number-input button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.numbered-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.dropzone {
    border: 2px dashed #ccc;
    width: 100%;
    min-height: 333px;
    height: 100%;
    background-size: cover !important;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.min-w-100 {
    MIN-WIDTH: 100px;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: var(--ck-color-base-border);
    min-height: 300px;
}

.min-w-300 {
    min-width: 333px;
    max-height: 333px;
}

.thumbs-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.thumb {
    display: flex;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    width: 197px;
    height: 240px;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
}

.thumb img {
    width: 100%;
    height: auto;
    max-height: 200px;
}

.max-w-300 {
    max-width: 300px;
}
/* Styl pro formulář */
.search-form {
    background-color: #f8f9fa; /* Světle šedé pozadí */
    border-radius: 8px; /* Zaoblené rohy */
    padding: 20px; /* Padding kolem obsahu */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Jemný stín */
    max-width: 600px; /* Maximální šířka formuláře */
    margin: 20px auto; /* Vycentrování formuláře */
}

/* Styl pro nadpis formuláře */
.search-form h2 {
    margin-bottom: 15px; /* Mezera pod nadpisem */
    color: #333; /* Tmavě šedá barva textu */
    font-size: 24px; /* Velikost textu */
    text-align: center; /* Vycentrování textu */
}

/* Styl pro vstupy */
.search-form input[type="text"],
.search-form input[type="number"] {
    width: 100%; /* Šířka 100% */
    padding: 10px; /* Padding uvnitř vstupu */
    margin-bottom: 15px; /* Mezera pod vstupem */
    border: 1px solid #ced4da; /* Světle šedý okraj */
    border-radius: 5px; /* Zaoblené rohy */
    font-size: 16px; /* Velikost textu */
    transition: border-color 0.3s; /* Hladký přechod pro barvu okraje */
}

/* Styl pro vstupy při zaostření */
.search-form input[type="text"]:focus,
.search-form input[type="number"]:focus {
    border-color: #007bff; /* Barva okraje při zaostření */
    outline: none; /* Odebrání defaultního rámečku */
}

/* Styl pro zaškrtávací políčka */
.search-form label {
    display: block; /* Blokové zobrazení */
    margin-bottom: 10px; /* Mezery pod zaškrtávacím políčkem */
    font-size: 16px; /* Velikost textu */
}

/* Styl pro tlačítko */
.search-form-button {
    background-color: #007bff; /* Modré pozadí */
    color: white; /* Bíla barva textu */
    border: none; /* Odebrání okraje */
    border-radius: 5px; /* Zaoblené rohy */
    padding: 10px 15px; /* Padding uvnitř tlačítka */
    font-size: 16px; /* Velikost textu */
    cursor: pointer; /* Ukazatel myši při najetí */
    transition: background-color 0.3s; /* Hladký přechod pro barvu pozadí */
}

/* Styl pro tlačítko při najetí myší */
.search-form-button:hover {
    background-color: #0056b3; /* Tmavší modrá při najetí */
}

/* Styl pro mobilní zařízení */
@media (max-width: 600px) {
    .search-form {
        padding: 15px; /* Menší padding pro mobil */
    }
}
.modal {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Poloprůhledné pozadí */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff; /* Bílá barva pozadí pro modální okno */
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px; /* Maximální šířka modálního okna */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Stín */
}

.close {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 15px;
}

.modal-section {
    margin-bottom: 20px; /* Mezery mezi sekcemi */
}

.modal-section h3 {
    margin-bottom: 10px; /* Mezery pod nadpisy sekcí */
}

.modal-section label {
    display: block; /* Zobrazení checkboxů pod sebou */
    margin-bottom: 10px; /* Mezery mezi checkboxy */
}

.modal-submit-button {
    background-color: #007BFF; /* Barva pozadí tlačítka */
    color: #fff; /* Barva textu tlačítka */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.modal-submit-button:hover {
    background-color: #0056b3; /* Tmavší modrá při najetí myší */
}
.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* Mezery nad tlačítky */
}

.modal-submit-button {
    background-color: #007BFF; /* Barva pozadí tlačítka */
    color: #fff; /* Barva textu tlačítka */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.modal-submit-button:hover {
    background-color: #0056b3; /* Tmavší modrá při najetí myší */
}

.modal-reset-button {
    background-color: #dc3545; /* Barva pozadí pro reset */
    color: #fff; /* Barva textu */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.modal-reset-button:hover {
    background-color: #c82333; /* Tmavší červená při najetí myší */
}
