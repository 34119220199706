/* Hlavní navbar */
.navbar {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Kontejner pro položky navbaru */
.navbar-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 44px;
}

/* Levá část navbaru */
.navbar-left {
    display: flex;
    align-items: center;
}

/* Odkaz na logo */
.logo-link {
    display: inline-block;
}

/* Logo */
.logo {
    height: 40px;
}

/* Hlavní menu */
.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
}

/* Rozbalené menu */
.menu.open {
    display: block !important;
}

/* Položka menu */
.menu-item {
    position: relative;
}

/* Odkaz v položce menu */
.menu-item > a {
    text-decoration: none;
    color: #2E8B57;
    display: flex;
    align-items: center;
}

/* Ikona šipky */
.arrow-icon {
    margin-left: 5px;
}

.sub-items-div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0;
    padding: 12px;
}

/* Submenu */
.submenu {
    list-style: none;
    padding: 0;
    position: absolute;
    top: 100%;
    left: -6px;
    background-color: transparent;
    display: none;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-flow: dense;
    gap: 10px;
    z-index: 31;
    max-width: 1200px;
}

.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 31;
    background-color: #FFFFFF;
    margin-top: 23px;
    border-radius: 0.5rem;
}

.category {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: bold;
}

/* Zobrazí submenu při hoveru */
.menu-item:hover .submenu {
    display: block; /* Použití grid layoutu při hoveru */
}

.grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 220px);
    width: 100%;
}

.menu-a-item {
    display: flex;
    width: 100%;
    margin: 0 5px;
    justify-content: space-between;
}

.grid-item {
    display: flex;
    position: relative;
    justify-content: space-between;
}

/* Položky submenu */
.submenu > li {
    padding: 10px;
    width: 200px; /* Šířka položky v submenu */
    box-sizing: border-box;
    border-bottom: 1px solid #ddd; /* Oddělení položek */
}

/* Odkaz v položce submenu */
.submenu > li > a {
    text-decoration: none;
    color: #2E8B57;
    display: block;
}

/* Hover efekt pro odkaz v položce submenu */
.submenu > li > a:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 5px;
}

/* Prvky navbaru vpravo */
.navbar-right {
    display: flex;
    align-items: center;
}

/* Ikona boxu */
.icon-box {
    display: inline-block;
    margin-left: 1px;
}

/* Ikona */
.icon {
    font-size: 18px;
}

/* Hamburger menu */
.hamburger {
    cursor: pointer;
}

/* Položky menu */
.menu li {
    display: flex;
    gap: 7px;
    color: #2E8B57;
    cursor: pointer;
    align-items: center;
}
